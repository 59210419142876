/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'jquery';
import 'jquery-ujs';
import 'react_ujs';
import 'bootstrap';
import 'bootstrap-tokenfield';
// eslint-disable-next-line import/no-unresolved
import 'cocoon-js-vanilla';
import { identify } from '../src/utils/analytics';

import sentryInit from '../sentryInit';

sentryInit();

const { id: userId, ...userTraits } = window.identity || {};

if (userId) {
  identify(userId, userTraits);
}

import '../SetTimezone';
import '../phone_mask';
import '../date_time_picker';
import '../date_range';
import '../address_geocode';
import '../address_prefill';
import '../field_sync';
import '../order_directions';
import '../order_map';
import '../GooglePlacesAutocomplete';
import '../collapsable';
import '../radio_collapse';
import '../editable';
import '../customer-edit';
import '../copy_text';
import '../user_roles';
import '../token_fields';
import '../CreditCardForm';
import '../OrderMapUpdater';
import '../coverage_area_map_form';
import '../order_adjustments';
import '../driver_payment_profile';
import '../horizontal_cards_focus';
import '../sms_token_field';
import '../tooltips';
import '../organizations_pricing';
import '../pricing_zone_config';
import '../OrganizationReferralInput';
import '../PricingZoneMarketInput';
import '../self-provisioning';
import '../direct_uploads';
import '../autoupload';
import '../currency_input';
import '../ad_hoc_driver_payments';
import '../terms_and_conditions_modal';
import '../toggle_branch_views';
import '../volume_discounts';
import '../admin_organization_settings';
import '../admin_organizations_toggle_deactivated_orgs';
import '../admin_couriers_toggle_archived_couriers';
import '../toggle_deactivated_users';
import '../custom_settings';
import '../persistent_banner';
import '../capability_form';
import '../intercom_logout';
import '../email_validation';

require('@rails/activestorage').start();

import Chartkick from 'chartkick';
window.Chartkick = Chartkick;

// for Chart.js
// eslint-disable-next-line import/no-unresolved
import Chart from 'chart.js';
window.Chart = Chart;
window.Chart.defaults.global.defaultFontFamily =
  '"Nunito Sans", Helvetica, Arial, sans-serif';

// // Support component names relative to this directory:
var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
